import React, { useEffect, useRef } from "react";
import "./LandingMain.scss";

const MainComponent = ({ pais = "Chile" }) => {
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!heroRef.current) return;
      const scrollY = window.scrollY;
      const opacity = Math.max(1 - scrollY / 700, 0.2);
      const transform = `translateY(${scrollY * 0.4}px)`;
      heroRef.current.style.opacity = opacity.toString();
      heroRef.current.style.transform = transform;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <main>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-background" ref={heroRef} />
        <div className="hero-overlay" />
        <div className="hero-content">
          <div className="hero-tagline">Publicidad Digital | DOOH | Torres Ejecutivas</div>
          <h1>
            Líder en Publicidad Digital en <span>Torres Ejecutivas</span>
          </h1>
          <p>
            Conecte con una audiencia premium en los espacios más exclusivos de Santiago, a través de nuestra red de
            pantallas digitales en ascensores y zonas estratégicas.
          </p>
          <div className="hero-buttons">
            <button
              onClick={() => document.getElementById("contacto")?.scrollIntoView({ behavior: "smooth" })}
              className="btn-primary"
            >
              Contáctenos
            </button>
            <button
              onClick={() => document.getElementById("servicios")?.scrollIntoView({ behavior: "smooth" })}
              className="btn-outline"
            >
              Nuestros Servicios
            </button>
          </div>
        </div>
        <div className="scroll-indicator">
          <svg
            onClick={() => document.getElementById("servicios")?.scrollIntoView({ behavior: "smooth" })}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </section>

      {/* Datos Clave y Beneficios Section */}
      <section id="servicios" className="datos-clave-section">
        <h2>Datos Clave y Beneficios</h2>
        <p>
          Al elegir la red Wecast de torres ejecutivas, su marca accede a una plataforma innovadora con ventajas únicas
        </p>
        <div className="datos-grid">
          <div className="datos-card">
            <div className="icon">🏢</div>
            <h3>Cobertura Exclusiva</h3>
            <p>
              Presencia en 17 torres ejecutivas de Santiago, con más de 90 pantallas digitales en ubicaciones
              estratégicas.
            </p>
          </div>
          <div className="datos-card">
            <div className="icon">👥</div>
            <h3>Audiencia Premium</h3>
            <p>Alcance de profesionales y tomadores de decisiones de alto nivel, destacados cada mes.</p>
          </div>
          <div className="datos-card">
            <div className="icon">⚡</div>
            <h3>Impacto Asegurado</h3>
            <p>Soportes digitales de alta calidad y tránsito controlado, garantizando atención completa del público.</p>
          </div>
          <div className="datos-card">
            <div className="icon">🖥️</div>
            <h3>Formatos Innovadores</h3>
            <p>Combinamos pantallas en elevadores, pasillos y recepciones con contenidos dinámicos y atractivos.</p>
          </div>
          <div className="datos-card">
            <div className="icon">📊</div>
            <h3>Soporte Integral</h3>
            <p>
              Equipo dedicado y tecnología propia (Wecast.TV) para gestión remota de contenidos, monitoreo en tiempo
              real y reportes detallados.
            </p>
          </div>
          <div className="datos-card">
            <div className="icon">🌐</div>
            <h3>Transformación Digital</h3>
            <p>
              Soluciones complementarias como pantallas LED, contenidos a medida e implementación de cartelería digital
              para su empresa.
            </p>
          </div>
        </div>
      </section>

      {/* Cobertura Section */}
      <section id="cobertura" className="cobertura-section">
        <h2>Our Global Coverage</h2>
        <p>We are expanding our reach to serve you better. Contact us to see if we cover your area.</p>
        <div className="map-placeholder">Map Placeholder</div>
      </section>

      {/* Torres Ejecutivas Section */}
      <section className="torres-section">
        <h2>Nuestras Torres Ejecutivas</h2>
        <p>Presencia exclusiva en las principales torres de Santiago con pantallas estratégicamente ubicadas</p>
        <div className="torres-card">
          <img
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=80"
            alt="Torre Costanera"
          />
          <div className="torres-info">
            <h3>Torre Costanera</h3>
            <p>La torre más alta de Sudamérica, con un flujo constante de ejecutivos y visitantes premium.</p>
            <div className="torres-stats">
              <div>
                <span>👥</span> Audiencia Mensual: 12,500
              </div>
              <div>
                <span>🖥️</span> Pantallas: 15
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Clientes Section */}
      <section id="clientes" className="clientes-section">
        <h2>Casos de Éxito y Clientes Relevantes</h2>
        <p>Grandes anunciantes ya confían en Wecast para amplificar su alcance en Chile</p>
        <div className="clientes-logos">
          <img src="https://via.placeholder.com/150x50?text=Unilever" alt="Unilever" />
          <img src="https://via.placeholder.com/150x50?text=L'Oréal" alt="L'Oréal" />
          <img src="https://via.placeholder.com/150x50?text=Samsung" alt="Samsung" />
          <img src="https://via.placeholder.com/150x50?text=BMW" alt="BMW" />
          <img src="https://via.placeholder.com/150x50?text=Coca-Cola" alt="Coca-Cola" />
          <img src="https://via.placeholder.com/150x50?text=Microsoft" alt="Microsoft" />
          <img src="https://via.placeholder.com/150x50?text=Amazon" alt="Amazon" />
          <img src="https://via.placeholder.com/150x50?text=Visa" alt="Visa" />
        </div>
        <div className="clientes-testimonial">
          <img src="https://via.placeholder.com/80" alt="Carlos Mendoza" className="testimonial-img" />
          <blockquote>
            "La visibilidad que logramos con la red de pantallas de Wecast fue fundamental para el éxito de nuestro
            lanzamiento. El impacto en nuestro target fue inmediato y superaron nuestras expectativas."
          </blockquote>
          <p className="testimonial-author">Carlos Mendoza, Gerente de Marca, TechInnovations</p>
        </div>
      </section>

      {/* Transformación Digital Section */}
      <section id="transformacion" className="transformacion-section">
        <h2>Transformación Digital</h2>
        <p>Innovación que complementa su estrategia publicitaria con soluciones digitales a medida</p>
        <div className="transformacion-content">
          <div className="transformacion-image">
            <img
              src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=80"
              alt="Transformación Digital"
            />
          </div>
          <div className="transformacion-text">
            <h3>Soluciones Integrales Para su Empresa</h3>
            <p>
              Además de nuestra oferta publicitaria, en Wecast Chile somos expertos en transformación digital, brindando
              soluciones que potencian la comunicación de su empresa puertas adentro y hacia sus clientes.
            </p>
            <ul>
              <li>
                Pantallas LED de última generación: Instalación de pantallas, interiores, exteriores, formatos curvos,
                transparentes o 3D
              </li>
              <li>
                Desarrollo de contenidos: Contenidos a medida, interactivos y dinámicos para comunicación efectiva
              </li>
              <li>Cartelería digital: Soluciones completas para puntos de venta, oficinas y espacios corporativos</li>
            </ul>
            <button
              onClick={() => document.getElementById("contacto")?.scrollIntoView({ behavior: "smooth" })}
              className="btn-primary"
            >
              Solicitar Información
            </button>
          </div>
        </div>
        <div className="transformacion-cards">
          <div className="transformacion-card">
            <div className="icon">🖥️</div>
            <h3>Consultoría</h3>
            <p>Evaluamos sus necesidades y diseñamos la estrategia digital más adecuada para su empresa</p>
          </div>
          <div className="transformacion-card">
            <div className="icon">⚙️</div>
            <h3>Implementación</h3>
            <p>Instalación de hardware, desarrollo de software y configuración de sistemas</p>
          </div>
          <div className="transformacion-card">
            <div className="icon">🔄</div>
            <h3>Mantenimiento</h3>
            <p>Soporte continuo, actualizaciones de contenido y mantenimiento preventivo</p>
          </div>
        </div>
      </section>

      {/* Contacto Section */}
      <section id="contacto" className="contacto-section">
        <h2>Contact Us</h2>
        <p>We’d love to hear from you! Send us a message and we’ll get back to you as soon as possible.</p>
        <form className="contacto-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required />
          <button type="submit" className="btn-primary">
            Send Message
          </button>
        </form>
      </section>
    </main>
  );
};

export default MainComponent;

/* eslint-disable no-console */
/* eslint-disable indent */
import React, { useCallback, useMemo } from "react";
import screenmediaLogo from "../../img/screenmedia-logo.png";
import visionaLogo from "../../img/visiona-logo.png";
import wecastLogo from "../../img/wecast-logo.png";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./stylesPdfCotizacion";

const today = new Date();
const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const date = `${day}-${month}-${today.getFullYear()}`;
const hour = String(today.getHours()).padStart(2, "0");
const minute = String(today.getMinutes()).padStart(2, "0");

const PdfCotizacion = ({
  ascensoresSeleccionados = 0,
  audienciaSeleccionadaAscensor = 0,
  audienciaSeleccionadaCowork = 0,
  audienciaSeleccionadaEspera = 0,
  audienciaSeleccionadaTotal = 0,
  audienciaSeleccionadaTotems = 0,
  codigoGenerado,
  coworkSeleccionados = 0,
  cpmAscensoresFinal = 0,
  cpmCoworkFinal = 0,
  cpmEsperaFinal = 0,
  cpmTotalFinal = 0,
  cpmTotemsFinal = 0,
  descuentoGlobal = 0,
  duracion = 0,
  esperaSeleccionados = 0,
  horasFinal = 0,
  impactosTotalAscensores = 0,
  impactosTotalCowork = 0,
  impactosTotalEspera = 0,
  impactosTotalTotems = 0,
  impactosTotales = 0,
  intervalos,
  inversionAscensoresFinal = 0,
  inversionCoworkFinal = 0,
  inversionEsperaFinal = 0,
  inversionFinalTotal = 0,
  inversionTotemsFinal = 0,
  isProgrammaticSelected,
  nombresEdificiosNoSeleccionados = [],
  nombresEdificiosSeleccionadosAscensores = [],
  nombresEdificiosSeleccionadosCowork = [],
  nombresEdificiosSeleccionadosEspera = [],
  nombresEdificiosSeleccionadosTotems = [],
  includeInstallingBuildings = false,
  options,
  pais = "Chile",
  plazo = 0,
  salidasAscensores = 0,
  salidasCowork = 0,
  salidasEspera = 0,
  salidasTotales = 0,
  salidasTotems = 0,
  tarifaFriaAscensores = 0,
  tarifaFriaCowork = 0,
  tarifaFriaEspera = 0,
  tarifaFriaTotal = 0,
  tarifaFriaTotems = 0,
  totemsSeleccionados = 0,
  usuarioApellido,
  usuarioNombre,
  frecuencia,
}) => {
  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const monedaSimbolo = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "CLP ",
    []
  );

  const ciudad = useCallback(
    (pais) =>
      ({
        Chile: "Santiago",
        Perú: "Lima",
        Uruguay: "Montevideo",
      })[pais] || "Lima",
    []
  );

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "CLP",
        Perú: "PEN",
        Uruguay: "URU",
      })[pais] || "CLP",
    []
  );

  const mailTextual = useCallback(
    (pais) =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: wecastLogo,
        Perú: visionaLogo,
        Uruguay: screenmediaLogo,
      })[pais] || visionaLogo,
    [pais]
  );

  // Calcular totales
  const totalPantallas = ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados + esperaSeleccionados;
  const nombreUsuario = `${usuarioNombre} ${usuarioApellido}`;

  // Calcular el número de columnas y el ancho de cada celda
  const numberOfColumns = isProgrammaticSelected ? 4 : 6;
  const cellWidth = `${100 / numberOfColumns}%`;

  const ordenarIntervalos = (a, b) => {
    const [horaA, minutoA] = a.split(" - ")[0].split(":").map(Number);
    const [horaB, minutoB] = b.split(" - ")[0].split(":").map(Number);

    if (horaA !== horaB) {
      return horaA - horaB;
    }
    return minutoA - minutoB;
  };

  const pluralizar = (cantidad, singular, plural) => {
    if (cantidad === 0) return "ningún edificio";
    return cantidad === 1 ? singular : plural;
  };

  // const estimateContentHeight = () => {
  //   // Adjust heights based on font sizes and padding from styles
  //   const rowHeight = 10; // Reduced from 15, considering fontSize: 9-11 and padding
  //   const headerHeight = 50;
  //   const tableHeaderTipoHeight = 25;
  //   const tableRowHeight = 20; // Each table row (fontSize: 9, paddingVertical: 5)
  //   const tableRows = 6; // 5 rows (Ascensores, Tótems, Espera, Cowork, Totales) + 1 header row
  //   const tableHeight = tableHeaderTipoHeight + tableRowHeight * tableRows; // Adjusted table height
  //   const detallesSectionHeight = 15; // Each "detallesSeccion" (PANTALLAS, AUDIENCIA, etc.), fontSize: 11-12
  //   const detallesSections = 6; // PANTALLAS, AUDIENCIA, PLAZO, EXHIBICIÓN, FRECUENCIA, DURACIÓN
  //   const detallesTotalHeight = detallesSections * detallesSectionHeight;
  //   const inventoryItems =
  //     (nombresEdificiosSeleccionadosAscensores.length > 0
  //       ? 1 + Math.ceil(nombresEdificiosSeleccionadosAscensores.length / 2)
  //       : 0) +
  //     (nombresEdificiosSeleccionadosTotems.length > 0
  //       ? 1 + Math.ceil(nombresEdificiosSeleccionadosTotems.length / 2)
  //       : 0) +
  //     (nombresEdificiosSeleccionadosCowork.length > 0
  //       ? 1 + Math.ceil(nombresEdificiosSeleccionadosCowork.length / 2)
  //       : 0) +
  //     (nombresEdificiosNoSeleccionados.length > 0 ? 1 + Math.ceil(nombresEdificiosNoSeleccionados.length / 2) : 0);
  //   const inventoryHeight = inventoryItems * rowHeight;
  //   const footerHeight = 60; // Reduced from 100, considering logo (height: auto), text (fontSize: 8-10)

  //   // Add padding and margins from styles.page and styles.contentContainer
  //   const pagePadding = 20; // styles.page padding: 20
  //   const contentPadding = 10; // styles.contentContainer padding: 10
  //   const totalPadding = pagePadding + contentPadding;

  //   const totalEstimatedHeight =
  //     headerHeight + tableHeight + detallesTotalHeight + inventoryHeight + footerHeight + totalPadding * 2;

  //   return totalEstimatedHeight;
  // };
  // const altoEstimado = estimateContentHeight();
  // console.log("altoestimado", altoEstimado);

  // Calculate total daily appearances
  const aparicionesDiarias = frecuencia && horasFinal && totalPantallas ? frecuencia * horasFinal * totalPantallas : 0;
  const aparicionesTotal = aparicionesDiarias ? aparicionesDiarias * plazo : 0;

  return (
    <Document>
      <Page
        size={[825, 650]} // Mantener el ancho
        orientation="landscape"
        style={[styles.page]}
      >
        <View style={[styles.contentContainer, { flex: 1, justifyContent: "flex-start", height: "100%" }]}>
          <Text style={styles.header} fixed>
            ~ CONFIDENCIAL ~
          </Text>
          <Text style={styles.title}>COTIZACIÓN de ESPACIOS PUBLICITARIOS en {operacionTextual(pais)}</Text>
          <Text style={styles.letrachica0}>
            {ciudad(pais)}, {date} @ {hour}:{minute} | Generado por: {nombreUsuario} | Cotización ID: {codigoGenerado}
          </Text>

          {/* Tabla con estilos ajustados */}
          <View style={[styles.table, { width: "100%" }]}>
            <View
              style={[
                styles.tableHeaderTipo,
                {
                  backgroundColor: isProgrammaticSelected ? "#006400" : "#FF8C00",
                  alignSelf: "center",
                },
              ]}
            >
              <Text>TIPO DE COMPRA: {isProgrammaticSelected ? "PROGRAMMATIC" : "DIGITAL"}</Text>
            </View>

            <View style={[styles.tableHeaderRow, styles.tableHeader]}>
              <Text style={[styles.tableCellQuote, { width: cellWidth }]}>CIRCUITO</Text>
              <Text style={[styles.tableCellQuote, { width: cellWidth }]}>IMPACTOS</Text>
              {!isProgrammaticSelected && <Text style={[styles.tableCellQuote, { width: cellWidth }]}>COTIZACIÓN</Text>}
              {!isProgrammaticSelected && (
                <View style={[styles.tableCellQuote, { width: cellWidth, backgroundColor: "#FF0000" }]}>
                  <Text style={([styles.tableCellQuoteColoredText], { color: "#ffffff" })}>DESCUENTO</Text>
                </View>
              )}
              <View style={[styles.tableCellQuote, { width: cellWidth, backgroundColor: "#008000" }]}>
                <Text style={([styles.tableCellQuoteColoredText], { color: "#ffffff" })}>INVERSIÓN</Text>
              </View>
              <View style={[styles.tableCellQuote, styles.lastCell, { width: cellWidth, backgroundColor: "#0000FF" }]}>
                <Text style={([styles.tableCellQuoteColoredText], { color: "#ffffff" })}>CPM</Text>
              </View>
            </View>

            {/* Datos de Ascensores */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData, { width: cellWidth }]}>ASCENSORES</Text>
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {ascensoresSeleccionados
                  ? impactosTotalAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                  {ascensoresSeleccionados
                    ? `${monedaSimbolo(pais)}${tarifaFriaAscensores.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </Text>
              )}
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData, { width: cellWidth }]}>
                  {ascensoresSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {ascensoresSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionAscensoresFinal
                        ? inversionAscensoresFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell, { width: cellWidth }]}>
                {ascensoresSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmAscensoresFinal
                        ? cpmAscensoresFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Tótems */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData, { width: cellWidth }]}>TÓTEMS</Text>
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {totemsSeleccionados
                  ? impactosTotalTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                  {totemsSeleccionados
                    ? `${monedaSimbolo(pais)}${tarifaFriaTotems.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </Text>
              )}
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData, { width: cellWidth }]}>
                  {totemsSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {totemsSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionTotemsFinal
                        ? inversionTotemsFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell, { width: cellWidth }]}>
                {totemsSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmTotemsFinal
                        ? cpmTotemsFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Espera */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData, { width: cellWidth }]}>ESPERA</Text>
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {esperaSeleccionados
                  ? impactosTotalEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                  {esperaSeleccionados
                    ? `${monedaSimbolo(pais)}${tarifaFriaEspera.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </Text>
              )}
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData, { width: cellWidth }]}>
                  {esperaSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {esperaSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionEsperaFinal
                        ? inversionEsperaFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell, { width: cellWidth }]}>
                {esperaSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmEsperaFinal
                        ? cpmEsperaFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Cowork */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData, { width: cellWidth }]}>COWORK iF</Text>
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {coworkSeleccionados
                  ? impactosTotalCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                  {coworkSeleccionados
                    ? `${monedaSimbolo(pais)}${tarifaFriaCowork.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </Text>
              )}
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData, { width: cellWidth }]}>
                  {coworkSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData, { width: cellWidth }]}>
                {coworkSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionCoworkFinal
                        ? inversionCoworkFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell, { width: cellWidth }]}>
                {coworkSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmCoworkFinal
                        ? cpmCoworkFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Totales */}
            <View style={[styles.tableRow, styles.tableTotal]}>
              <Text style={[styles.tableCellStart, styles.tableDataTotales, { width: cellWidth }]}>TOTALES</Text>
              <Text style={[styles.tableCellQuote, styles.tableDataTotales, { width: cellWidth }]}>
                {impactosTotales.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) || "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCellQuote, styles.tableDataTotales, { width: cellWidth }]}>
                  {totalPantallas
                    ? `${monedaSimbolo(pais)}${tarifaFriaTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : "-"}
                </Text>
              )}
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableDataTotales, { width: cellWidth }]}>
                  {descuentoGlobal
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableDataTotales, { width: cellWidth }]}>
                {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                  inversionFinalTotal
                    ? inversionFinalTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"
                }`}
              </Text>
              <Text style={[styles.tableCell, styles.tableDataTotales, styles.lastCell, { width: cellWidth }]}>
                {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                  cpmTotalFinal
                    ? cpmTotalFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.hr} />
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>PANTALLAS</Text>
            <Text style={styles.inventariosubTitulo}>{totalPantallas || "-"} en total</Text>
            <Text style={[styles.inventarioNombres]}>
              {[
                ascensoresSeleccionados ? `${ascensoresSeleccionados} pantallas en ascensores` : null,
                totemsSeleccionados ? `${totemsSeleccionados} tótems de circulación` : null,
                esperaSeleccionados ? `${esperaSeleccionados} pantallas de espera` : null,
                coworkSeleccionados ? `${coworkSeleccionados} pantallas en cowork` : null,
              ]
                .filter(Boolean) // Remove null values
                .join(", ")}{" "}
              {/* Join with commas and spaces */}
              {![ascensoresSeleccionados, totemsSeleccionados, esperaSeleccionados, coworkSeleccionados].some(
                Boolean
              ) && "-"}{" "}
              {/* Show "-" if all are falsy */}
            </Text>
          </View>
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>AUDIENCIA</Text>
            <Text style={styles.detallesText}>
              {audienciaSeleccionadaTotal.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) || "-"}{" "}
              personas únicas
            </Text>
          </View>
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>PLAZO DE PAUTA</Text>
            <Text style={styles.detallesText}>{plazo || "-"} días (lunes a viernes)</Text>
          </View>
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>EXHIBICIÓN</Text>
            <Text style={styles.detallesText}>
              {isProgrammaticSelected
                ? intervalos.length > 0
                  ? `Intervalos: ${intervalos.sort(ordenarIntervalos).join(" | ")} = ${horasFinal || "-"} horas por día`
                  : `No hay intervalos seleccionados, ${horasFinal || "-"} horas por día`
                : options.length > 0
                  ? `${options[0].label} = ${horasFinal || "-"} horas por día`
                  : `No hay opciones seleccionadas, ${horasFinal || "-"} horas por día`}
            </Text>
          </View>
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>FRECUENCIA</Text>
            {frecuencia ? (
              <>
                <Text style={styles.detallesText}>{frecuencia} apariciones por hora por pantalla</Text>
                <Text style={styles.detallesText}>
                  {aparicionesDiarias
                    ? aparicionesDiarias.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) + " apariciones por día totales"
                    : "- apariciones por día totales"}
                </Text>
                <Text style={styles.detallesText}>
                  {aparicionesTotal
                    ? aparicionesTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) + " apariciones totales campaña"
                    : "- apariciones totales campaña"}
                </Text>
              </>
            ) : (
              <Text style={styles.detallesText}>No especificada</Text>
            )}
          </View>
          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>DURACIÓN DEL CONTENIDO</Text>
            <Text style={styles.detallesText}>{duracion || "-"} segundos</Text>
          </View>

          <View style={styles.detallesSeccion}>
            <Text style={styles.detallesTitulo}>INVENTARIO</Text>
            <Text style={[styles.inventariosubTitulo]}>
              {(() => {
                const todosSeleccionados = [
                  ...nombresEdificiosSeleccionadosAscensores,
                  ...nombresEdificiosSeleccionadosTotems,
                  ...nombresEdificiosSeleccionadosEspera,
                  ...nombresEdificiosSeleccionadosCowork,
                ];
                const edificiosSeleccionadosUnicos = [...new Set(todosSeleccionados)].sort();
                return `${edificiosSeleccionadosUnicos.length} ${pluralizar(
                  edificiosSeleccionadosUnicos.length,
                  "edificio seleccionado",
                  "edificios seleccionados"
                )}`;
              })()}
            </Text>
            {(() => {
              const todosSeleccionados = [
                ...nombresEdificiosSeleccionadosAscensores,
                ...nombresEdificiosSeleccionadosTotems,
                ...nombresEdificiosSeleccionadosEspera,
                ...nombresEdificiosSeleccionadosCowork,
              ];
              const edificiosSeleccionadosUnicos = [...new Set(todosSeleccionados)].sort();
              return edificiosSeleccionadosUnicos.length > 0 ? (
                <Text style={[styles.inventarioNombres]}>{edificiosSeleccionadosUnicos.join(", ")}</Text>
              ) : null;
            })()}

            {(() => {
              if (nombresEdificiosNoSeleccionados.length > 0) {
                return (
                  <>
                    <Text style={[styles.inventariosubTitulo]}>
                      {`${nombresEdificiosNoSeleccionados.length} ${pluralizar(
                        nombresEdificiosNoSeleccionados.length,
                        "edificio no seleccionado",
                        "edificios no seleccionados"
                      )}`}
                    </Text>
                    <Text style={[styles.inventarioNombres]}>{nombresEdificiosNoSeleccionados.sort().join(", ")}</Text>
                  </>
                );
              }
              return null;
            })()}
          </View>
          <View style={styles.hr} />

          <View style={[styles.footer, { marginTop: 0 }]}>
            <Text style={styles.letrachica2}>
              Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en{" "}
              {isProgrammaticSelected ? "US$" : `${monedaTextual(pais)}`} y no incluyen impuestos. Exhibición de lunes a
              viernes de 7 AM a 9 PM según frecuencia y horario contratado. Propuesta incluye descuentos especiales que
              no podrán considerarse como antecedente. El cliente podrá cambiar materiales tantas veces como desee.
              Cotización válida para pautas a emitirse hasta diciembre 2025 inclusive.
            </Text>
            <Image style={styles.image} src={logo} />
            <Text style={styles.mail}>{mailTextual(pais)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfCotizacion;

import { log, supabase } from ".";

export const saveUsuario = async (usuario) => {
  try {
    const data = await supabase.insert("usuarios", usuario);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveUsuario", error);
    throw error;
  }
};

/* eslint-disable unicorn/prefer-module */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button"; // Importar Button para usarlo
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getOportunidades, getIntermediarios, updateOportunidad, log } from "../../utils";
import "./stylesVentasFacturas.scss";

export const VentasFacturas = () => {
  const [todasOportunidades, setTodasOportunidades] = useState([]);
  const [oportunidadesFiltradas, setOportunidadesFiltradas] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [filtroNumeroFactura, setFiltroNumeroFactura] = useState("");
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroCobrada, setFiltroCobrada] = useState("");
  const [numerosFacturaUnicos, setNumerosFacturaUnicos] = useState([]);
  const [clientesUnicos, setClientesUnicos] = useState([]);
  const [filtroAno, setFiltroAno] = useState(new Date().getFullYear().toString());
  const [filtroMeses, setFiltroMeses] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [intermediariosUnicos, setIntermediariosUnicos] = useState([]);

  // Estados para el modal
  const [showModal, setShowModal] = useState(false);
  const [oportunidadEditada, setOportunidadEditada] = useState(null);
  const [formData, setFormData] = useState({
    facturanumero: "",
    facturafecha: "",
    facturafechacobro: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      }[pais] || "es-UY"),
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      }[pais] || "$"),
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      }[pais] || 0),
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes, 10) - 1] || "";
  }, []);

  // Inicializar datos
  useEffect(() => {
    const initialize = async () => {
      const allOportunidades = await getOportunidades({ pais });
      const allIntermediarios = await getIntermediarios({ pais });

      setTodasOportunidades(allOportunidades);
      setIntermediarios(allIntermediarios);

      // Opciones únicas de intermediarios (usando nombres)
      const intermediariosUnicos = [...new Set(allIntermediarios.map((i) => i.nombre))].filter(Boolean).sort();
      setIntermediariosUnicos(intermediariosUnicos);
    };
    initialize();
  }, [pais]);

  // Filtrar oportunidades y asignar comisiones
  useEffect(() => {
    const filtrarOportunidades = () => {
      const yearSeleccionado = filtroAno ? parseInt(filtroAno, 10) : null;
  
      const filtradas = todasOportunidades
        .filter((oportunidad) => {
          const fechaFactura = oportunidad.facturafecha ? new Date(oportunidad.facturafecha) : null;
          const mesFactura = fechaFactura ? (fechaFactura.getMonth() + 1).toString().padStart(2, "0") : null;
          const mesOportunidad = oportunidad.mes ? oportunidad.mes.toString().padStart(2, "0") : null;
  
          const mesParaFiltrar = fechaFactura ? mesFactura : mesOportunidad;
  
          const mesFiltrado =
            filtroMeses.length > 0
              ? filtroMeses.includes(mesParaFiltrar)
              : true;
  
          let intermediarioNombre = oportunidad.intermediario;
          if (intermediarioNombre && !isNaN(intermediarioNombre) && typeof intermediarioNombre !== "object") {
            const intermediarioAsociado = intermediarios.find(
              (intermediario) => intermediario.id === parseInt(intermediarioNombre)
            );
            intermediarioNombre = intermediarioAsociado ? intermediarioAsociado.nombre : intermediarioNombre;
          }
  
          const intermediarioFiltrado =
            filtroIntermediario ? intermediarioNombre === filtroIntermediario : true;
  
          const perteneceAlAno =
            yearSeleccionado
              ? parseInt(oportunidad.ano, 10) === yearSeleccionado ||
                (fechaFactura && fechaFactura.getFullYear() === yearSeleccionado)
              : true;
  
          return (
            mesFiltrado &&
            intermediarioFiltrado &&
            (filtroNumeroFactura ? oportunidad.facturanumero === filtroNumeroFactura : true) &&
            (filtroCliente ? oportunidad.cliente === filtroCliente : true) &&
            (filtroCobrada
              ? (filtroCobrada === "Sí" ? oportunidad.facturafechacobro : !oportunidad.facturafechacobro)
              : true) &&
            perteneceAlAno &&
            oportunidad.estatus === "100%" &&
            !oportunidad.eliminada
          );
        })
        .map((oportunidad) => {
          let intermediarioNombre = oportunidad.intermediario;
          let comision = 0;
  
          let anoOportunidad;
          if (oportunidad.ano) {
            anoOportunidad = parseInt(oportunidad.ano, 10);
          } else if (oportunidad.facturafecha) {
            anoOportunidad = new Date(oportunidad.facturafecha).getFullYear();
          } else {
            anoOportunidad = new Date().getFullYear();
          }
  
          if (intermediarioNombre) {
            if (!isNaN(intermediarioNombre) && typeof intermediarioNombre !== "object") {
              const intermediarioAsociado = intermediarios.find(
                (intermediario) => intermediario.id === parseInt(intermediarioNombre)
              );
              if (intermediarioAsociado) {
                intermediarioNombre = intermediarioAsociado.nombre;
                if (
                  intermediarioAsociado.comision_2 !== null &&
                  intermediarioAsociado.comision_2_fechainicio &&
                  intermediarioAsociado.comision_2_fechafin
                ) {
                  const fechaInicio = new Date(intermediarioAsociado.comision_2_fechainicio);
                  const fechaFin = new Date(intermediarioAsociado.comision_2_fechafin);
                  const anoInicio = fechaInicio.getFullYear();
                  const anoFin = fechaFin.getFullYear();
  
                  comision = anoOportunidad >= anoInicio && anoOportunidad <= anoFin ? intermediarioAsociado.comision_2 || 0 : intermediarioAsociado.comision || 0;
                } else {
                  comision = intermediarioAsociado.comision || 0;
                }
              }
            } else if (typeof intermediarioNombre === "string") {
              const intermediarioAsociado = intermediarios.find(
                (intermediario) => intermediario.nombre === intermediarioNombre
              );
              if (intermediarioAsociado) {
                intermediarioNombre = intermediarioAsociado.nombre;
                if (
                  intermediarioAsociado.comision_2 !== null &&
                  intermediarioAsociado.comision_2_fechainicio &&
                  intermediarioAsociado.comision_2_fechafin
                ) {
                  const fechaInicio = new Date(intermediarioAsociado.comision_2_fechainicio);
                  const fechaFin = new Date(intermediarioAsociado.comision_2_fechafin);
                  const anoInicio = fechaInicio.getFullYear();
                  const anoFin = fechaFin.getFullYear();
  
                  comision = anoOportunidad >= anoInicio && anoOportunidad <= anoFin ? intermediarioAsociado.comision_2 || 0 : intermediarioAsociado.comision || 0;
                } else {
                  comision = intermediarioAsociado.comision || 0;
                }
              }
            }
          }
  
          return {
            ...oportunidad,
            intermediario: intermediarioNombre || "SIN INTERMEDIARIO",
            comision,
          };
        });
  
      setOportunidadesFiltradas(filtradas);
  
      const numerosFactura = [...new Set(filtradas.map((o) => o.facturanumero))]
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
      setNumerosFacturaUnicos(numerosFactura);
  
      const clientes = [...new Set(filtradas.map((o) => o.cliente))].filter(Boolean).sort();
      setClientesUnicos(clientes);
    };
  
    if (todasOportunidades.length > 0) {
      filtrarOportunidades();
    }
  }, [
    filtroMeses,
    filtroNumeroFactura,
    filtroCliente,
    filtroCobrada,
    filtroAno,
    filtroIntermediario,
    todasOportunidades,
    intermediarios,
  ]);

  const handleOpenModal = (oportunidad) => {
    setOportunidadEditada(oportunidad);
    setFormData({
      facturanumero: oportunidad.facturanumero || "",
      facturafecha: oportunidad.facturafecha
        ? new Date(oportunidad.facturafecha).toISOString().split("T")[0]
        : "",
      facturafechacobro: oportunidad.facturafechacobro
        ? new Date(oportunidad.facturafechacobro).toISOString().split("T")[0]
        : "",
    });
    setShowModal(true);
    setError(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOportunidadEditada(null);
    setFormData({ facturanumero: "", facturafecha: "", facturafechacobro: "" });
    setError(null);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    if (!oportunidadEditada) return;
  
    try {
      setLoading(true);
      setShowModal(false);
  
      const updatedOportunidad = {
        facturanumero: formData.facturanumero || null,
        facturafecha: formData.facturafecha || null,
        facturafechacobro: formData.facturafechacobro || null,
        updated_at: new Date().toISOString(),
      };
  
      await updateOportunidad({
        id: oportunidadEditada.id,
        oportunidad: updatedOportunidad,
      });
  
      setTodasOportunidades((prev) =>
        prev.map((op) =>
          op.id === oportunidadEditada.id ? { ...op, ...updatedOportunidad } : op
        )
      );
  
      setLoading(false);
    } catch (error) {
      if (error.code === "23505") {
        setError("El número de factura ya existe");
        setShowModal(true);
      } else {
        setError("Hubo un error al actualizar la oportunidad, prueba de nuevo.");
        setShowModal(true);
      }
      log.error("handleSaveChanges", error);
      setLoading(false);
    }
  };

  const calcularTotales = () => {
    let totalVenta = 0;
    let totalComision = 0;
    let totalNeto = 0;
    let totalCobrado = 0;

    oportunidadesFiltradas.forEach(({ importe, facturafechacobro, comision }) => {
      totalVenta += importe || 0;
      totalComision += importe * (comision / 100);
      if (facturafechacobro) {
        totalCobrado += importe || 0;
      }
    });

    totalNeto = totalVenta - totalComision;

    return {
      totalVenta,
      totalComision,
      totalNeto,
      totalCobrado,
    };
  };

  const totales = calcularTotales();
  const fecha = { day: "2-digit", month: "2-digit", year: "numeric" };

  if (todasOportunidades.length === 0 || intermediarios.length === 0) return <Loader />;

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check" style={{ marginBottom: "5px" }}>
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMeses.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={(e) => handleMesChange(e.target.value)}
          style={{ marginLeft: "-15px" }}
        />
        <label
          className="form-check-label"
          htmlFor={`mes${index}`}
          style={{ marginLeft: "10px" }}
        >
          {mes}
        </label>
      </div>
    ));

  const handleMesChange = (mes) => {
    setFiltroMeses((prev) =>
      prev.includes(mes) ? prev.filter((item) => item !== mes) : [...prev, mes]
    );
  };

  return (
    <div className="container-facturas-detalle">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div className="table-responsive">
        {/* Mini Dashboard */}
        <div className="row justify-content-center mb-3">
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                BRUTO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalVenta.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                INTERMEDIACIÓN
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalComision.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                NETO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalNeto.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                COBRADO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${totales.totalCobrado.toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
              <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                PENDIENTE DE COBRO
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">{`${moneda(pais)} ${(
                  totales.totalVenta - totales.totalCobrado
                ).toLocaleString(localString(pais), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}`}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-4">
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroNumeroFactura}
              onChange={(e) => setFiltroNumeroFactura(e.target.value)}
            >
              <option value="">Número de Factura</option>
              {numerosFacturaUnicos.map((numero) => (
                <option key={numero} value={numero}>{numero}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroCliente}
              onChange={(e) => setFiltroCliente(e.target.value)}
            >
              <option value="">Cliente Publicitario</option>
              {clientesUnicos.map((cliente) => (
                <option key={cliente} value={cliente}>{cliente}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="white border"
                id="dropdown-basic"
                className={`form-control text-start ${filtroMeses.length > 0 ? "active-filter" : ""}`}
              >
                {filtroMeses.length > 0 ? filtroMeses.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ maxHeight: "370px", overflowY: "auto" }}>
                {renderMesOptions()}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroIntermediario}
              onChange={(e) => setFiltroIntermediario(e.target.value)}
            >
              <option value="">Intermediario</option>
              {intermediariosUnicos.map((intermediario) => (
                <option key={intermediario} value={intermediario}>
                  {intermediario}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroAno}
              onChange={(e) => setFiltroAno(e.target.value)}
            >
              <option value="">HTD</option>
              {["2024", "2025"].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              value={filtroCobrada}
              onChange={(e) => setFiltroCobrada(e.target.value)}
            >
              <option value="">Cobrada</option>
              <option value="Sí">Sí</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <table className="table table-hover">
          <thead className="text-center align-middle">
            <tr>
              <th className="col-status">STATUS</th>
              <th className="col-numero-factura">NÚMERO FACTURA</th>
              <th className="col-fecha-factura">FECHA FACTURA</th>
              <th className="col-cliente">CLIENTE PUBLICITARIO</th>
              <th className="col-intermediario">INTERMEDIARIO</th>
              <th className="col-venta">VENTA</th>
              <th className="col-comision">COMISIÓN %</th>
              <th className="col-comision">COMISIÓN {moneda(pais)}</th>
              <th className="col-neto">NETO</th>
              <th className="col-cobrada">COBRADA</th>
              <th className="col-editar">EDITAR</th>
            </tr>
          </thead>
          <tbody>
            {oportunidadesFiltradas.length === 0 ? (
              <tr>
                <td colSpan="11" className="text-center">
                  No hay facturas ni ventas según los filtros seleccionados :(
                </td>
              </tr>
            ) : (
              oportunidadesFiltradas.map(
                ({
                  estatus,
                  mes,
                  cliente,
                  comision,
                  facturafecha,
                  facturafechacobro,
                  intermediario,
                  id,
                  importe,
                  plazocobro,
                  facturanumero,
                }) => (
                  <tr key={id}>
                    <td className="col-status bg-success text-white">{estatus}</td>
                    <td className="col-numero-factura">{facturanumero ? facturanumero : "-"}</td>
                    <td className="col-fecha-factura">
                      {facturafecha
                        ? new Date(`${facturafecha}T00:00:00`).toLocaleDateString("es-ES", fecha)
                        : "-"}
                    </td>
                    <td className="col-cliente">{cliente}</td>
                    <td className="col-intermediario">{intermediario}</td>
                    <td className="col-venta text-end">{`${moneda(pais)} ${importe?.toLocaleString(
                      localString(pais),
                      {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      }
                    ) || "error"}`}</td>
                    <td className="col-comision text-center">
                      {comision}%
                    </td>
                    <td className="col-comision text-end">
                      {intermediario === "DIRECTO" || intermediario === "SIN INTERMEDIARIO"
                        ? "-"
                        : `${moneda(pais)} ${(
                          importe * (comision / 100)
                        ).toLocaleString(localString(pais), {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        })}`}
                    </td>
                    <td className="col-neto text-end">
                      {`${moneda(pais)} ${(importe - importe * (comision / 100)).toLocaleString(
                        localString(pais),
                        {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        }
                      )}`}
                    </td>
                    <td
                      className="col-cobrada"
                      style={{
                        backgroundColor: facturafechacobro
                          ? "#198754"
                          : facturafecha
                            ? "#dc3545"
                            : "#4c5257",
                        color: "white",
                      }}
                    >
                      {facturafechacobro
                        ? new Date(`${facturafechacobro}T00:00:00`).toLocaleDateString("es-ES", fecha)
                        : facturafecha
                          ? "Sin cobrar"
                          : "Sin facturar"}
                    </td>
                    <td className="col-editar text-center align-middle">
                      <FaEdit onClick={() => handleOpenModal({ estatus, mes, cliente, comision, facturafecha, facturafechacobro, intermediario, id, importe, plazocobro, facturanumero })} />
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>

      {/* Modal para editar factura */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Factura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form>
            <Form.Group className="mb-3" controlId="formNumeroFactura">
              <Form.Label>Número de Factura</Form.Label>
              <Form.Control
                type="text"
                name="facturanumero"
                value={formData.facturanumero}
                onChange={handleInputChange}
                placeholder="Ingrese el número de factura"
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFechaFactura">
              <Form.Label>Fecha de Factura</Form.Label>
              <Form.Control
                type="date"
                name="facturafecha"
                value={formData.facturafecha}
                onChange={handleInputChange}
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFechaCobro">
              <Form.Label>Fecha de Cobro</Form.Label>
              <Form.Control
                type="date"
                name="facturafechacobro"
                value={formData.facturafechacobro}
                onChange={handleInputChange}
                disabled={loading}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={loading}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveChanges} disabled={loading}>
            {loading ? "Guardando..." : "Guardar Cambios"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
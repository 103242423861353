/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import "./LandingNavBar.scss";

const LandingNavBar = ({ pais = "Chile" }) => {
  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  return (
    <div>
      <img src={logo} alt="Wecast Logo" className="navbar-logo" />
      <nav className="navbar-nav">
        <NavLink href="#servicios">Servicios</NavLink>
        <NavLink href="#cobertura">Cobertura</NavLink>
        <NavLink href="#clientes">Clientes</NavLink>
        <NavLink href="#transformacion">Transformación Digital</NavLink>
        <button
          className="navbar-contact-btn"
          onClick={() => document.getElementById("contacto")?.scrollIntoView({ behavior: "smooth" })}
        >
          Contacto
        </button>
      </nav>
    </div>
  );
};

const NavLink = ({ href, children }) => (
  <a href={href} className="navbar-link">
    {children}
  </a>
);

export default LandingNavBar;
